import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  location_vi: '',
  location_en: '',
  location_jp: '',
  name_vi: '',
  name_en: '',
  name_jp: '',
  address_vi: '',
  address_en: '',
  address_jp: '',
  link: '',
  map: '',
  phone: '',
  zalo: '',
  facebook: '',
  isHeadquarter: 0,
  isMain: 0,
  search: '',
  status: 0,
};
export const branchItemSlice = createSlice({
  name: 'branch',
  initialState,
  reducers: {
    setItem: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
    setDefaultValue: (_, action) => {
      if (!action?.payload?.id) return initialState;
      return { ...action.payload };
    },
    resetData: () => {
      return initialState;
    },
  },
});
export const { setItem, setDefaultValue, resetData } = branchItemSlice.actions;

export default branchItemSlice.reducer;
