import React from 'react';
import Branch from './views/pages/branch/Branch';
import BranchDetail from './views/pages/branch/BranchDetail';
import Company from './views/pages/company/company';
import CompanyDetail from './views/pages/company/CompanyDetail';
import Product from './views/pages/product/Product';
import ProductDetail from './views/pages/product/ProductDetail';
import Service from './views/pages/service/Service';
import ServiceDetail from './views/pages/service/ServiceDetail';
import Inquiry from './views/pages/inquiry/Inquiry';
import InquiryDetail from './views/pages/inquiry/InquiryDetail';

const Login = React.lazy(() => import('./views/pages/login/Login'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const Dashboard = React.lazy(() => import('./views/pages/dashboard/Dashboard'));
const ConfigManagerment = React.lazy(() => import('./views/pages/config/Config'));

const allRoutes = [
  {
    path: '/dashboard',
    key: 'dashboard',
    name: 'Dashboard',
    component: Dashboard,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/config',
    name: 'Cấu hình',
    component: ConfigManagerment,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/branch',
    name: 'Danh sách chi nhánh',
    component: Branch,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/branch/:id',
    name: 'Tạo mới chi nhánh',
    component: BranchDetail,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/company',
    name: 'Danh sách nhà cung cấp',
    component: Company,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/company/:id',
    name: 'Tạo mới nhà cung cấp',
    component: CompanyDetail,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/product',
    name: 'Danh sách sản phẩm',
    component: Product,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/product/:id',
    name: 'Tạo mới sản phẩm',
    component: ProductDetail,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/service',
    name: 'Danh sách dịch vụ',
    component: Service,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/service/:id',
    name: 'Tạo mới dịch vụ',
    component: ServiceDetail,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/inquiry',
    name: 'Danh sách yêu cầu',
    component: Inquiry,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/inquiry/:id',
    name: 'Tạo mới yêu cầu',
    component: InquiryDetail,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/login',
    key: 'Login',
    name: 'Login',
    component: Login,
    exact: true,
    isPrivate: false,
    hasLayout: false,
  },
  {
    path: '/404',
    key: 'Page404',
    name: 'Page404',
    component: Page404,
    exact: true,
    isPrivate: false,
    hasLayout: false,
  },
  {
    path: '/500',
    key: 'Page500',
    name: 'Page500',
    component: Page500,
    exact: true,
    isPrivate: false,
    hasLayout: false,
  },
];

export const routes = allRoutes.reduce((obj, item) => {
  const temp = { ...obj };
  if (item.key) temp[item.key.toLowerCase()] = item;
  return temp;
}, {});

export default allRoutes;
