/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useParams, useHistory } from 'react-router-dom';
import {
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CCardFooter,
  CFormGroup,
  CLabel,
  CInput,
  CButton,
  CForm,
  CTextarea,
  CInputCheckbox,
} from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';

import 'react-confirm-alert/src/react-confirm-alert.css';
import { resetData } from 'src/slices/NewsItemSlice';
import SunEditorCustom from 'src/components/SunEditor';
import { detailBranch, upsertBranch } from 'src/slices/BranchSlice';
import { useTrans } from 'src/contexts/language';

const BranchDetail = () => {
  const { t, language } = useTrans();
  const routeParams = useParams();
  const { id } = routeParams;
  const [openModalProject, setOpenModalProject] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const branchItem = useSelector(state => state.branchItem);
  const dispatch = useDispatch();
  const history = useHistory();
  const [validated, setValidated] = useState(false);

  const formik = useFormik({
    initialValues: {
      language,
      name_vi: branchItem.name_vi || '',
      name_en: branchItem.name_en || '',
      name_jp: branchItem.name_jp || '',
      location_vi: branchItem.location_vi || '',
      location_en: branchItem.location_en || '',
      location_jp: branchItem.location_jp || '',
      address_vi: branchItem.address_vi || '',
      address_en: branchItem.address_en || '',
      address_jp: branchItem.address_jp || '',
      link: branchItem.link || '',
      map: branchItem.map || '',
      phone: branchItem.phone || '',
      zalo: branchItem.zalo || '',
      // facebook: branchItem.facebook || '',
      isHeadquarter: branchItem.isHeadquarter || 0,
      isMain: branchItem.isMain || 0,
      status: branchItem.status || 0,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name_vi: Yup.string().required(`${t('Vui lòng điền')} ${t('Tên tiếng')} ${t('Việt')}`),
      location_vi: Yup.string().required(`${t('Vui lòng điền')} ${t('Mô tả tiếng')} ${t('Việt')}`),
      address_vi: Yup.string().required(
        `${t('Vui lòng điền')} ${t('Nội dung tiếng')} ${t('Việt')}`,
      ),
      link: Yup.string().required(`${t('Vui lòng điền')} ${t('link')}`),
      map: Yup.string().required(`${t('Vui lòng điền')} ${t('Bản đồ')}`),
    }),
    onSubmit: async vals => {
      const res = await dispatch(
        upsertBranch({
          id: id === 'create' ? '' : id,
          data: { ...vals },
        }),
      );
      if (res.payload === true) {
        history.push(`/branch`);
      }
    },
  });
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    setFieldValue,
    values, // use this if you want controlled components
    errors,
    initialValues,
  } = formik;
  const imageChange = e => {
    setSelectedImage(e.target.files[0]);
  };

  const setData = (name, value) => {
    setFieldValue(name, value);
  };

  const handleClean = e => {
    e.preventDefault();
    dispatch(resetData());
    setSelectedImage();
    history.push(`/branch`);
  };

  useEffect(() => {
    (async () => {
      if (id !== 'create') {
        await dispatch(detailBranch(id));
      } else {
        dispatch(resetData());
        setSelectedImage();
      }
    })();
  }, [id]);

  return (
    <>
      <CForm
        action=""
        method="post"
        encType="multipart/form-data"
        className="form-horizontal"
        onSubmit={handleSubmit}
        validated={validated}
      >
        <CCard>
          <CCardHeader>
            {t('Tạo mới')} {t('chi nhánh')}
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="name_vi">
                    {t('Tên tiếng')} {t('Việt')}
                  </CLabel>
                  <CInput
                    id="name_vi"
                    name="name_vi"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.name_vi || ''}
                  />
                  {touched.name_vi && errors.name_vi ? (
                    <small className="text-danger">{t(errors.name_vi)}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="name_en">
                    {t('Tên tiếng')} {t('Anh')}
                  </CLabel>
                  <CInput
                    id="name_en"
                    name="name_en"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.name_en || ''}
                  />
                  {touched.name_en && errors.name_en ? (
                    <small className="text-danger">{t(errors.name_en)}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="name_vi">
                    {t('Tên tiếng')} {t('Nhật')}
                  </CLabel>
                  <CInput
                    id="name_jp"
                    name="name_jp"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.name_jp || ''}
                  />
                  {touched.name_jp && errors.name_jp ? (
                    <small className="text-danger">{t(errors.name_jp)}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="location_vi">
                    {t('Vị trí tiếng')} {t('Việt')}
                  </CLabel>
                  <CTextarea
                    id="location_vi"
                    name="location_vi"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.location_vi || ''}
                  />
                  {touched.location_vi && errors.location_vi ? (
                    <small className="text-danger">{t(errors.location_vi)}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="location_en">
                    {t('Vị trí tiếng')} {t('Anh')}
                  </CLabel>
                  <CTextarea
                    id="location_en"
                    name="location_en"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.location_en || ''}
                  />
                  {touched.location_en && errors.location_en ? (
                    <small className="text-danger">{t(errors.location_en)}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="location_jp">
                    {t('Vị trí tiếng')} {t('Nhật')}
                  </CLabel>
                  <CTextarea
                    id="location_jp"
                    name="location_jp"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.location_jp || ''}
                  />
                  {touched.location_jp && errors.location_jp ? (
                    <small className="text-danger">{t(errors.location_jp)}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="12">
                <CFormGroup>
                  <CLabel htmlFor="address_vi">
                    {t('Địa chỉ tiếng')} {t('Việt')}
                  </CLabel>
                  <CTextarea
                    id="address_vi"
                    name="address_vi"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.address_vi || ''}
                  />
                  {touched.address_vi && errors.address_vi ? (
                    <small className="text-danger">{t(errors.address_vi)}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="12">
                <CFormGroup>
                  <CLabel htmlFor="address_en">
                    {t('Địa chỉ tiếng')} {t('Anh')}
                  </CLabel>
                  <CTextarea
                    id="address_en"
                    name="address_en"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.address_en || ''}
                  />
                  {touched.address_en && errors.address_en ? (
                    <small className="text-danger">{t(errors.address_en)}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="12">
                <CFormGroup>
                  <CLabel htmlFor="address_jp">
                    {t('Địa chỉ tiếng')} {t('Nhật')}
                  </CLabel>
                  <CTextarea
                    id="address_jp"
                    name="address_jp"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.address_jp || ''}
                  />
                  {touched.address_jp && errors.address_jp ? (
                    <small className="text-danger">{t(errors.address_jp)}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow>

            <CRow>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="link">{t('Trang web')}</CLabel>
                  <CInput
                    id="link"
                    name="link"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.link || ''}
                  />
                  {touched.link && errors.link ? (
                    <small className="text-danger">{t(errors.link)}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="phone">{t('Số điện thoại')}</CLabel>
                  <CInput
                    id="phone"
                    name="phone"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.phone || ''}
                  />
                  {touched.phone && errors.phone ? (
                    <small className="text-danger">{t(errors.phone)}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="zalo">Zalo</CLabel>
                  <CInput
                    id="zalo"
                    name="zalo"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.zalo || ''}
                  />
                  {touched.zalo && errors.zalo ? (
                    <small className="text-danger">{t(errors.zalo)}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6" />
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="map">{t('Bản đồ')}</CLabel>

                  <SunEditorCustom
                    key={initialValues.map}
                    onChange={value => setData('map', value)}
                    name="map"
                    showToolbar
                    height="100"
                    setContents={values.map}
                  />
                  {touched.map && errors.map ? (
                    <small className="text-danger">{t(errors.map)}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="4">
                <CFormGroup variant="custom-checkbox" inline>
                  <CInputCheckbox
                    id="status"
                    name="status"
                    checked={values.status ? 1 : 0}
                    onChange={e => setData(e.target.name, values.status ? 0 : 1)}
                  />
                  <CLabel htmlFor="status">{t('Kích hoạt')}</CLabel>
                </CFormGroup>
              </CCol>
              <CCol xs="4">
                <CFormGroup variant="custom-checkbox" inline>
                  <CInputCheckbox
                    id="isHeadquarter"
                    name="isHeadquarter"
                    checked={values.isHeadquarter ? 1 : 0}
                    onChange={e => setData(e.target.name, values.isHeadquarter ? 0 : 1)}
                  />
                  <CLabel htmlFor="isHeadquarter">{t('Headquarter')}</CLabel>
                </CFormGroup>
              </CCol>
              <CCol xs="4">
                <CFormGroup variant="custom-checkbox" inline>
                  <CInputCheckbox
                    id="isMain"
                    name="isMain"
                    checked={values.isMain ? 1 : 0}
                    onChange={e => setData(e.target.name, values.isMain ? 0 : 1)}
                  />
                  <CLabel htmlFor="isMain">{t('Chi nhánh chính')}</CLabel>
                </CFormGroup>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter align="center">
            <CButton color="primary" style={{ marginRight: 10 }} type="submit">
              {t('Lưu')}
            </CButton>
            <CButton color="secondary" onClick={handleClean}>
              {t('Không')}
            </CButton>
          </CCardFooter>
        </CCard>
      </CForm>
      <style>
        {`
          .horizontal-slider {
            width: 100%;
            max-width: 700px;
            height: 10vh;
            margin: auto;
          }
          .horizontal-text {
            max-width: 700px;
            width: 100%;
            margin: auto;
            display: flex;
            justify-content: space-between;
          }
          .horizontal-text div {
            text-align: center;
          }
          .horizontal-text p {
            background-color: #fca432;
            padding: 7px;
            color: white;
            border-radius: 15px;
          }
          .example-thumb {
            cursor: pointer;
            position: absolute;
            z-index: 100;
            background: #ffffff;
            border: 5px solid #fca432;
            border-radius: 100%;
            display: block;
            box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
          }
          .example-thumb.active {
            background-color: grey;
          }
          .example-track {
            position: relative;
            background: #ddd;
          }
          .example-track.example-track-0 {
            background: #fca432;
          }
          .horizontal-slider .example-track {
            top: 15px;
            height: 5px;
          }
          .horizontal-slider .example-thumb {
            top: 12px;
            width: 10px;
            outline: none;
            height: 10px;
            line-height: 38px;
          }
        `}
      </style>
    </>
  );
};

export default BranchDetail;
