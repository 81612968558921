/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useParams, useHistory } from 'react-router-dom';
import {
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CCardFooter,
  CFormGroup,
  CLabel,
  CInput,
  CButton,
  CForm,
  CInputCheckbox,
  CInputFile,
  CSelect,
} from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';

import 'react-confirm-alert/src/react-confirm-alert.css';
import apiClient from 'src/api/api-client';
import { uploadFileURI } from 'src/api';
import { detailProduct, upsertProduct } from 'src/slices/ProductSlice';
import { resetData } from 'src/slices/ProductItemSlice';
import SunEditorCustom from 'src/components/SunEditor';
import { fetchCompanies } from 'src/slices/CompanySlice';
import { useTrans } from 'src/contexts/language';

const ProductDetail = () => {
  const { t, language } = useTrans();
  const routeParams = useParams();
  const { id } = routeParams;
  const [openModalProject, setOpenModalProject] = useState(false);
  const [selectedLogo, setSelectedLogo] = useState();
  const [selectedImage, setSelectedImage] = useState();
  const productItem = useSelector(state => state.productItem);
  const company = useSelector(state => state.company);
  const dispatch = useDispatch();
  const history = useHistory();
  const [validated, setValidated] = useState(false);

  const formik = useFormik({
    initialValues: {
      language,
      name_vi: productItem.name_vi || '',
      name_en: productItem.name_en || '',
      name_jp: productItem.name_jp || '',
      title_vi: productItem.title_vi || '',
      title_en: productItem.title_en || '',
      title_jp: productItem.title_jp || '',
      description_vi: productItem.description_vi || '',
      description_en: productItem.description_en || '',
      description_jp: productItem.description_jp || '',
      link: productItem.link || '',
      company_id: productItem.company_id || '',
      status: productItem.status || 0,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name_vi: Yup.string().required(`${t('Vui lòng điền')} ${t('Tên tiếng')} ${t('Việt')}`),
      company_id: Yup.string().required(`${t('Vui lòng chọn')} ${t('Nhà cung cấp')}`),
    }),
    onSubmit: async vals => {
      let { image, logo } = productItem;
      if (selectedImage) {
        const uploadFormData = new FormData();
        uploadFormData.append('file', selectedImage);
        const uploadRes = await apiClient.handleRequest({
          method: 'post',
          url: `${uploadFileURI}`,
          data: uploadFormData,
        });
        image = uploadRes.data.data;
      }
      if (selectedLogo) {
        const uploadFormData = new FormData();
        uploadFormData.append('file', selectedLogo);
        const uploadRes = await apiClient.handleRequest({
          method: 'post',
          url: `${uploadFileURI}`,
          data: uploadFormData,
        });
        logo = uploadRes.data.data;
      }

      const res = await dispatch(
        upsertProduct({
          id: id === 'create' ? '' : id,
          data: { ...vals, image },
        }),
      );
      if (res.payload === true) {
        history.push(`/product`);
      }
    },
  });
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    setFieldValue,
    values, // use this if you want controlled components
    errors,
    initialValues,
  } = formik;
  const imageChange = e => {
    setSelectedImage(e.target.files[0]);
  };
  const logoChange = e => {
    setSelectedLogo(e.target.files[0]);
  };

  const setData = (name, value) => {
    setFieldValue(name, value);
  };

  const handleClean = e => {
    e.preventDefault();
    dispatch(resetData());
    setSelectedImage();
    history.push(`/product`);
  };

  useEffect(() => {
    (async () => {
      if (id !== 'create') {
        await dispatch(detailProduct(id));
      } else {
        dispatch(resetData());
        setSelectedImage();
      }
    })();
  }, [id]);

  useEffect(() => {
    dispatch(fetchCompanies());
  }, []);

  return (
    <>
      <CForm
        action=""
        method="post"
        encType="multipart/form-data"
        className="form-horizontal"
        onSubmit={handleSubmit}
      >
        <CCard>
          <CCardHeader>{t('Tạo mới sản phẩm')}</CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="company_id">{t('Nhà cung cấp')}</CLabel>
                  <CSelect
                    custom
                    name="company_id"
                    id="company_id"
                    value={values.company_id}
                    onChange={async e => {
                      await setData('company_id', +e.target.value);
                    }}
                  >
                    {[<option>Select</option>].concat(
                      company.entities.map(item => {
                        return (
                          <option value={item.id} key={item.id}>
                            {item.name_vi}
                          </option>
                        );
                      }),
                    )}
                  </CSelect>
                  {touched.company_id && errors.company_id ? (
                    <small className="text-danger">{t(errors.company_id)}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="image">{t('Tải')} image</CLabel>
                  <CFormGroup variant="custom-checkbox">
                    <CInputFile
                      custom
                      id="image"
                      onChange={e => {
                        imageChange(e);
                      }}
                      accept="image/*"
                    />
                    <CLabel htmlFor="image" variant="custom-file">
                      {selectedImage?.name || t('Chọn file')}
                    </CLabel>
                  </CFormGroup>
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="link">Link</CLabel>
                  <CInput
                    id="link"
                    name="link"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.link || ''}
                  />
                  {touched.link && errors.link ? (
                    <small className="text-danger">{t(errors.link)}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                {selectedImage && (
                  <div>
                    <img
                      style={{ width: '-webkit-fill-available' }}
                      src={URL.createObjectURL(selectedImage)}
                      alt=""
                    />
                  </div>
                )}
                {!selectedImage && productItem.image && (
                  <div>
                    <img
                      style={{ width: '-webkit-fill-available' }}
                      src={productItem.image}
                      alt=""
                    />
                  </div>
                )}
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="name_vi">
                    {t('Tên tiếng')} {t('Việt')}
                  </CLabel>
                  <CInput
                    id="name_vi"
                    name="name_vi"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.name_vi || ''}
                  />
                  {touched.name_vi && errors.name_vi ? (
                    <small className="text-danger">{t(errors.name_vi)}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="name_en">
                    {t('Tên tiếng')} {t('Anh')}
                  </CLabel>
                  <CInput
                    id="name_en"
                    name="name_en"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.name_en || ''}
                  />
                  {touched.name_en && errors.name_en ? (
                    <small className="text-danger">{t(errors.name_en)}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="name_vi">
                    {t('Tên tiếng')} {t('Nhật')}
                  </CLabel>
                  <CInput
                    id="name_jp"
                    name="name_jp"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.name_jp || ''}
                  />
                  {touched.name_jp && errors.name_jp ? (
                    <small className="text-danger">{t(errors.name_jp)}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="title_vi">
                    {t('Mô tả ngắn tiếng')} {t('Việt')}
                  </CLabel>
                  <CInput
                    id="title_vi"
                    name="title_vi"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.title_vi || ''}
                  />
                  {touched.title_vi && errors.title_vi ? (
                    <small className="text-danger">{t(errors.title_vi)}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="title_en">
                    {t('Mô tả ngắn tiếng')} {t('Anh')}
                  </CLabel>
                  <CInput
                    id="title_en"
                    name="title_en"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.title_en || ''}
                  />
                  {touched.title_en && errors.title_en ? (
                    <small className="text-danger">{t(errors.title_en)}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="title_jp">
                    {t('Mô tả ngắn tiếng')} {t('Nhật')}
                  </CLabel>
                  <CInput
                    id="title_jp"
                    name="title_jp"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.title_jp || ''}
                  />
                  {touched.title_jp && errors.title_jp ? (
                    <small className="text-danger">{t(errors.title_jp)}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="description_vi">
                    {t('Nội dung tiếng')} {t('Việt')}
                  </CLabel>
                  <SunEditorCustom
                    key={initialValues.description_vi}
                    onChange={value => setData('description_vi', value)}
                    name="description_vi"
                    showToolbar
                    height="100"
                    setContents={values.description_vi}
                  />
                  {touched.description_vi && errors.description_vi ? (
                    <small className="text-danger">{t(errors.description_vi)}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="description_en">
                    {t('Nội dung tiếng')} {t('Anh')}
                  </CLabel>
                  <SunEditorCustom
                    key={initialValues.description_en}
                    onChange={value => setData('description_en', value)}
                    name="description_en"
                    showToolbar
                    height="100"
                    setContents={values.description_en}
                  />
                  {touched.description_en && errors.description_en ? (
                    <small className="text-danger">{t(errors.description_en)}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="description_jp">
                    {t('Nội dung tiếng')} {t('Nhật')}
                  </CLabel>
                  <SunEditorCustom
                    key={initialValues.description_jp}
                    onChange={value => setData('description_jp', value)}
                    name="description_jp"
                    showToolbar
                    height="100"
                    setContents={values.description_jp}
                  />
                  {touched.description_jp && errors.description_jp ? (
                    <small className="text-danger">{t(errors.description_jp)}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow>

            <CRow>
              <CCol xs="4">
                <CFormGroup variant="custom-checkbox" inline>
                  <CInputCheckbox
                    id="status"
                    name="status"
                    checked={values.status ? 1 : 0}
                    onChange={e => setData(e.target.name, values.status ? 0 : 1)}
                  />
                  <CLabel htmlFor="status">{t('Kích hoạt')}</CLabel>
                </CFormGroup>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter align="center">
            <CButton color="primary" style={{ marginRight: 10 }} type="submit">
              {t('Lưu')}
            </CButton>
            <CButton color="secondary" onClick={handleClean}>
              {t('Không')}
            </CButton>
          </CCardFooter>
        </CCard>
      </CForm>
      <style>
        {`
          .horizontal-slider {
            width: 100%;
            max-width: 700px;
            height: 10vh;
            margin: auto;
          }
          .horizontal-text {
            max-width: 700px;
            width: 100%;
            margin: auto;
            display: flex;
            justify-content: space-between;
          }
          .horizontal-text div {
            text-align: center;
          }
          .horizontal-text p {
            background-color: #fca432;
            padding: 7px;
            color: white;
            border-radius: 15px;
          }
          .example-thumb {
            cursor: pointer;
            position: absolute;
            z-index: 100;
            background: #ffffff;
            border: 5px solid #fca432;
            border-radius: 100%;
            display: block;
            box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
          }
          .example-thumb.active {
            background-color: grey;
          }
          .example-track {
            position: relative;
            background: #ddd;
          }
          .example-track.example-track-0 {
            background: #fca432;
          }
          .horizontal-slider .example-track {
            top: 15px;
            height: 5px;
          }
          .horizontal-slider .example-thumb {
            top: 12px;
            width: 10px;
            outline: none;
            height: 10px;
            line-height: 38px;
          }
        `}
      </style>
    </>
  );
};

export default ProductDetail;
