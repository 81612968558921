/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CDataTable,
  CBadge,
  CPagination,
  CFormGroup,
  CLabel,
  CInput,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupText,
  CButton,
  CSelect,
} from '@coreui/react';
import { CIcon } from '@coreui/icons-react';
import { freeSet } from '@coreui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Status from 'src/enums/Status';
import { deleteProduct, fetchProducts } from 'src/slices/ProductSlice';
import { setItem } from 'src/slices/ProductItemSlice';
import { useTrans } from 'src/contexts/language';

const Product = () => {
  const { t } = useTrans();
  const fields = [
    { key: 'name_vi', label: `${t('Tên tiếng')} ${t('Việt')}` },
    { key: 'company_name_vi', label: t('Nhà cung cấp') },
    { key: 'status', label: t('Trạng thái') },
    { key: 'actions', label: t('Thao tác') },
  ];

  const product = useSelector(state => state.product);
  const productItem = useSelector(state => state.productItem);

  const dispatch = useDispatch();
  const history = useHistory();

  const getBadge = status => {
    switch (status) {
      case 1:
        return 'success';
      case 0:
        return 'danger';
      default:
        return 'primary';
    }
  };

  const handleDelete = item => {
    dispatch(deleteProduct({ ...item }));
  };

  const confirmDelete = item => {
    confirmAlert({
      title: t('Xác nhận xóa sản phẩm'),
      message: t('Chắc chắn muốn xóa sản phẩm này?'),
      buttons: [
        {
          label: 'Delete',
          className: 'bg-danger',
          onClick: () => {
            handleDelete(item);
          },
        },
        {
          label: 'Cancel',
          onClick: () => {},
        },
      ],
    });
  };

  const { entities, loading, currentPage, totalPage, total } = product;
  const { status, search, date } = productItem;

  useEffect(() => {
    dispatch(fetchProducts({ page: currentPage, limit: 10 }));
  }, []);

  const handleSearch = () => {
    dispatch(fetchProducts({ search, status, page: 1, limit: 10 }));
  };

  const setFilter = e => {
    const { name, value } = e.target;
    dispatch(setItem({ name, value }));
  };

  const navigateServiceDetail = id => {
    history.push(`product/${id}`);
    // dispatch(
    //   NavigationActions.navigate({
    //     routeName: ,
    //   }),
    // );
  };

  return (
    <>
      <CCard>
        <CCardHeader>{t('Danh sách sản phẩm')}</CCardHeader>
        <CCardBody>
          <CRow>
            <CCol md="4">
              <CFormGroup>
                <CLabel htmlFor="search">{t('Tìm kiếm')}</CLabel>
                <CInputGroup>
                  <CInput
                    type="text"
                    id="search"
                    name="search"
                    placeholder={t('Nhập thông tin muốn tìm kiếm')}
                    autoComplete="off"
                    onChange={setFilter}
                  />
                  <CInputGroupAppend>
                    <CInputGroupText className="bg-info text-white">
                      <CIcon content={freeSet.cilSearch} />
                    </CInputGroupText>
                  </CInputGroupAppend>
                </CInputGroup>
              </CFormGroup>
            </CCol>

            <CCol md="4">
              <CFormGroup>
                <CLabel htmlFor="select_status">{t('Trạng thái')}</CLabel>
                <CSelect custom name="status" id="status" value={status || ''} onChange={setFilter}>
                  <option value="">{t('Tất cả')}</option>
                  {Object.keys(Status).map(key => (
                    <option value={Status[key].value}>{Status[key].name}</option>
                  ))}
                </CSelect>
              </CFormGroup>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <CButton
                className="mb-2 float-right"
                color="info"
                onClick={() => {
                  handleSearch();
                }}
              >
                {t('Tìm kiếm')}
              </CButton>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <CCard>
                <CCardBody>
                  <CDataTable
                    items={entities}
                    fields={fields}
                    hover
                    striped
                    bordered
                    size="sm"
                    loading={loading === 'pending'}
                    itemsPerPage={10}
                    activePage={1}
                    scopedSlots={{
                      status: item => (
                        <td>
                          <CBadge className="pointer" role="button" color={getBadge(item.status)}>
                            {item.status ? t('Hoạt động') : t('Không hoạt động')}
                          </CBadge>
                        </td>
                      ),
                      actions: item => (
                        <td>
                          <span
                            onClick={() => {
                              navigateServiceDetail(item.id);
                            }}
                            className="pointer"
                            role="button"
                          >
                            <CIcon name="cilPencil" className="text-warning" />
                          </span>
                          <span className="ml-2" role="button" onClick={() => confirmDelete(item)}>
                            <CIcon name="cilTrash" className="text-danger" />
                          </span>
                        </td>
                      ),
                    }}
                  />
                  {total > 0 && (
                    <CRow>
                      <CCol>
                        <b>
                          {t('Tổng')}: {total}
                        </b>
                      </CCol>
                      <CCol className="text-right">
                        <CPagination
                          activePage={currentPage}
                          pages={totalPage}
                          onActivePageChange={page => dispatch(fetchProducts({ page, limit: 10 }))}
                          align="end"
                        />
                      </CCol>
                    </CRow>
                  )}
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </>
  );
};

export default Product;
