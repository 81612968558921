import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import en from '../lang/en.json';
import vi from '../lang/vi.json';
import jp from '../lang/jp.json';

const LanguageContext = createContext({});
const trans = { en, vi, jp };
const defaultLanguage = 'vi';

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(defaultLanguage);

  const updateLanguage = useCallback(value => {
    localStorage.setItem('language', value);
    setLanguage(value);
  }, []);

  useEffect(() => {
    const value = localStorage.getItem('language');
    updateLanguage(value || defaultLanguage);
  }, [updateLanguage]);

  const t = useCallback(key => trans[language]?.[key] || key, [language]);
  const tkey = useCallback(
    (obj, key) => (obj ? obj[`${key}_${language}`] || obj[`${key}_vi`] : ''),
    [language],
  );

  return (
    <LanguageContext.Provider value={{ updateLanguage, t, tkey, language }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useTrans = () => useContext(LanguageContext);
