import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  name_vi: '',
  name_en: '',
  name_jp: '',
  title_vi: '',
  title_en: '',
  title_jp: '',
  description_vi: '',
  description_en: '',
  description_jp: '',
  link: '',
  image: '',
  company_id: '',
  search: '',
  status: 0,
};
export const productItemSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setItem: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
    setDefaultValue: (_, action) => {
      if (!action?.payload?.id) return initialState;
      return { ...action.payload };
    },
    resetData: () => {
      return initialState;
    },
  },
});
export const { setItem, setDefaultValue, resetData } = productItemSlice.actions;

export default productItemSlice.reducer;
