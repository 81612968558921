import { configureStore } from '@reduxjs/toolkit';
import AuthReducer from './slices/AuthSlice';
import SidebarReducer from './slices/SidebarSlice';
import JobReducer from './slices/JobSlice';
import CategoryReducer from './slices/JobCatergoriesSlice';
import MessageReducer from './slices/MessageSlice';
import JobItemReducer from './slices/JobItemSlice';
import cvReducer from './slices/CVSlice';
import contactReducer from './slices/ContactSlice';
import blockReducer from './slices/BlockSlice';
import bodReducer from './slices/BodSlice';
import bodItemReducer from './slices/BodItemSlice';
import newReducer from './slices/NewSlice';
import newItemReducer from './slices/NewItemSlice';
import serviceCategoryReducer from './slices/ServiceCategorySlice';
import potentialCandidateReducer from './slices/PotentialCandidateSlice';
import configReducer from './slices/ConfigSlice';
import introductionItemReducer from './slices/IntroductionItemSlice';
import introductionReducer from './slices/IntroductionSlice';
import ReviewSlice from './slices/ReviewSlice';
import ReviewItemSlice from './slices/ReviewItemSlice';
import ActivitySlice from './slices/ActivitySlice';
import ActivityItemSlice from './slices/ActivityItemSlice';
import RegistrationSlice from './slices/RegistrationSlice';
import RegistrationItemSlice from './slices/RegistrationItemSlice';
import NewsSlice from './slices/NewsSlice';
import NewsItemSlice from './slices/NewsItemSlice';
import CourseSlice from './slices/CourseSlice';
import CourseItemSlice from './slices/CourseItemSlice';
import TuitionSlice from './slices/TuitionSlice';
import TuitionItemSlice from './slices/TuitionItemSlice';
import PromotionSlice from './slices/PromotionSlice';
import PromotionItemSlice from './slices/PromotionItemSlice';
import CoachSlice from './slices/CoachSlice';
import CoachItemSlice from './slices/CoachItemSlice';
import BranchSlice from './slices/BranchSlice';
import BranchItemSlice from './slices/BranchItemSlice';
import CompanySlice from './slices/CompanySlice';
import CompanyItemSlice from './slices/CompanyItemSlice';
import ProductSlice from './slices/ProductSlice';
import ProductItemSlice from './slices/ProductItemSlice';
import ServiceSlice from './slices/ServiceSlice';
import ServiceItemSlice from './slices/ServiceItemSlice';
import InquirySlice from './slices/InquirySlice';
import InquiryItemSlice from './slices/InquiryItemSlice';

export default configureStore({
  reducer: {
    auth: AuthReducer,
    sidebar: SidebarReducer,
    job: JobReducer,
    category: CategoryReducer,
    message: MessageReducer,
    jobItem: JobItemReducer,
    cv: cvReducer,
    contact: contactReducer,
    block: blockReducer,
    bod: bodReducer,
    bodItem: bodItemReducer,
    new: newReducer,
    newItem: newItemReducer,
    review: ReviewSlice,
    reviewItem: ReviewItemSlice,
    activity: ActivitySlice,
    activityItem: ActivityItemSlice,
    registration: RegistrationSlice,
    registrationItem: RegistrationItemSlice,
    news: NewsSlice,
    newsItem: NewsItemSlice,
    serviceCategory: serviceCategoryReducer,
    potentialCandidate: potentialCandidateReducer,
    config: configReducer,
    introductionItem: introductionItemReducer,
    introduction: introductionReducer,
    course: CourseSlice,
    courseItem: CourseItemSlice,
    tuition: TuitionSlice,
    tuitionItem: TuitionItemSlice,
    promotion: PromotionSlice,
    promotionItem: PromotionItemSlice,
    coach: CoachSlice,
    coachItem: CoachItemSlice,
    branch: BranchSlice,
    branchItem: BranchItemSlice,
    company: CompanySlice,
    companyItem: CompanyItemSlice,
    product: ProductSlice,
    productItem: ProductItemSlice,
    service: ServiceSlice,
    serviceItem: ServiceItemSlice,
    inquiry: InquirySlice,
    inquiryItem: InquiryItemSlice,
  },
});
