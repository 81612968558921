import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  course_id: '',
  month: '',
  price_vi: '',
  price_en: '',
  price_jp: '',
  search: '',
  status: '',
};
export const tuitionItemSlice = createSlice({
  name: 'tuition',
  initialState,
  reducers: {
    setItem: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
    setDefaultValue: (_, action) => {
      if (!action?.payload?.id) return initialState;
      return { ...action.payload };
    },
    resetData: () => {
      return initialState;
    },
  },
});
export const { setItem, setDefaultValue, resetData } = tuitionItemSlice.actions;

export default tuitionItemSlice.reducer;
