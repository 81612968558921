const Status = {
  InActive: {
    value: 0,
    name: 'Không kích hoạt',
  },
  Active: {
    value: 1,
    name: 'Kích hoạt',
  },
};

export default Status;
